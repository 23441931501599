.single-services{
    .delivery,
    .continuos-dev{
        .title-wrapper{
            text-align: center;
            h5.underline{
                display: inline-block;
                text-align: center;
                margin-bottom: 130px;
                &:after{
                    background-color: #C6D6FF;
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    top: -5px;
                    @media (max-width:$xsmall) {
                        width: 40px;
                        height: 40px;
                        right: 0;
                        left: auto;
                    }
                }
            }
        }        
        .card-wrapper-icon{
            justify-content: center;
            .card{
                padding: 80px 50px;
                overflow: hidden;
                .icon-wrapper{
                    width: 100%;
                    height: 140px;
                    position: relative;
                    display: block;
                    .icon-container{
                        position: absolute;
                        right: -85px;
                        bottom: -125px;
                        width: 256px;
                        height: 256px;
                        background: #FFFEDA;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }    
}