.page-template-page-services{
    .main-header{
        .logo{
            // filter: invert(1);
        }
    }
    .how{            
        .underline{
            &:after{
                background-color: #BDFFE7;
            }            
        }
        .how-svg{
            margin-top: 130px;
            &.desktop{
                display: block;
                @media screen and (max-width: $small){
                    display: none;
                }
            }
            &.mobile{
                display: none;
                margin-top: 50px;                
                @media screen and (max-width: $small){
                    display: block;
                }                
                // .inner_circle,
                // .outter_circles{
                //     opacity: 0;
                //     visibility: hidden;
                //     transform: scale(0);
                // }
            }
            text.number{ 
                fill:#BDFFE7; 
                font-size:5em; 
                font-family:'Poppins'; 
                font-weight: 600;
                @media screen and (max-width: $small) {
                    font-size: 2em;
                }
            }
            .content{
                fill: $black;
            }
            .text-16{
                font-weight: 300;
                opacity: .84;
                @media screen and (max-width: 1500px) {
                    font-size:20px; 
                }
                @media screen and (max-width: $small) {
                    font-size:10px; 
                }
                // @include font-size(1.5em);
            }
            .text-24{
                @media screen and (max-width: 1500px) {
                    // font-size:20px; 
                }
                // @include font-size(24px);
            }
            .subtitle-24{
                @media screen and (max-width: $small) {
                    font-size: 1em;
                }
            }
        }

        .second-title{
            margin: 175px 0 65px 0;
        }

        .swiper{
            margin-top: 130px;
            cursor: pointer;
            .swiper-slide{
                .project-wrapper{
                    padding: 0 50px;
                    &:hover{
                        .img-wrapper{
                            img{                            
                                transform: scale(1.1)!important;
                            }
                        }
                    }
                    @media screen and (max-width: $small) {
                        padding: 10px;
                    }
                    img{
                        height: 700px;
                        transition: all .3s $ease1!important;
                        @media screen and (max-width: $xsmall) {
                            height: 400px;
                        }   
                    }
                    .post-info{
                        display: flex;
                        flex-direction: revert;
                        align-items: center;
                        margin-top: 10px;
                        // p.caption{
                        //     border: 1px solid rgba($black, .12);
                        //     padding: 10px 15px;
                        //     margin-left: 20px;
                        //     // @include font-size(14px);
                        // }
                    }
                    .post-tags{
                        display: flex;
                        flex-direction: row;
                        .text-20{
                            margin-right: 10px;
                            color: rgba($black, .64);
                            // @include font-size(20px);
                            &:after{
                                content: ',';
                            }
                            &:last-of-type{
                                &::after{
                                    content: '';
                                }
                            }
                        }
                    }
                }                                
            }
        }
    }
}