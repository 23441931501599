.home{
    .approach{
        .underline{
            &:after{
                background-color: #FFE0D2;
            }
        }
        .column{
            &:nth-child(1) .column-content .pop{
                background-color: #FFFEDA;
                top: -5px;
                right: -25%;
                @media (max-width: $xsmall){
                    top: 5px;
                    right: -25px;
                }
            }
            &:nth-child(2) .column-content .pop{
                background-color: #D2F7FF;
                top: 25px;
                right: -15%;
                @media (max-width: $xsmall){
                    top: 15px;
                    right: -15%;
                }
            }
            &:nth-child(3) .column-content .pop{
                background-color: #FFE0D2;
                bottom: 0px;
                right: -15%;
            }
            &:nth-child(4) .column-content .pop{
                background-color: #F2E1FF; 
                top: 25px;
                right: -25%;
                @media (max-width: $xsmall){
                    top: 15px;
                    right: -15%;
                }
            }
            &:nth-child(5) .column-content .pop{
                background-color: #C6D6FF;
                right: -16%;
                top: 17px;
            }
            &:nth-child(6) .column-content .pop{
                background-color: #FFC7D4;
                right: -25%;
                top: 0px;
            }
        }
        .column-content{
            padding: 0 50px 110px 0;
            @media (max-width: $xsmall) {
                padding: 0 0px 50px 0;;
            }
            .img-wrapper{
                display: inline-block;
                overflow: inherit;
                .pop{
                    width: 75px;
                    height: 75px;
                    position: absolute;                                        
                    border-radius: 50%;
                    z-index: -1;     
                    top: -5px;
                    right: -25%;   
                    @media (max-width: $xsmall){
                        width: 55px;
                        height: 55px;
                    }                                
                }
                img{
                    height: 80px;
                    width: 75px;
                    margin-bottom: 15px;
                    object-fit: contain;
                    @media (max-width: $xsmall){
                        width: 50px;
                        height: auto;
                    }
                }
            }            
            .text-16{
                margin-top: 15px;
                opacity: .84;
            }
        }
    }
}