.blob-wrapper{
    // position: absolute;
    // height: 100%;
    // width: 100%;
    // top: 0;
    // left: 0;
    // z-index: 1;
    .blob{    
        border-radius: 50%;
        position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;    
        // margin: -70px 0 0 -70px;  
        mix-blend-mode: difference;
        z-index: 1;
    }
}


