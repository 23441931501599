.single-services{
    .features{
        padding: 100px 0;
        .row{
            margin-bottom: 100px;
            &.even{
                justify-content: flex-start;
            }
            &.odd{
                justify-content: flex-end;
            }
            &:last-of-type{
                margin-bottom: 0;
            }                            
        }            
    }
    .card{
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
        padding: 50px;                                                            
        @media (max-width:$small) {
            padding: 20px;
        }
        .block-content{
            display: flex;       
            @media (max-width:$small) {
                flex-direction: column;
            }
            .container{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 40px;
                @media (max-width:$small) {
                    align-items: center;
                }
                .title-32{
                    // margin: 40px 0 20px 0;
                    @media (max-width:$xsmall) {
                        font-size: 25px;
                        margin: 0;
                    }
                }
                .icon-wrapper{
                    margin-right: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 100px;
                    min-height: 100px;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    position: relative;                
                    position: relative;
                    @media (max-width:$xsmall) {
                        margin-right: 20px;
                        min-width: 50px;
                        min-height: 50px;
                        width: 50px;
                        height: 50px;
                    }
                    .back{
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: .4;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;                    
                        display: block;
                    }
                    .icon{
                        z-index: 1;
                        width: 50px;
                        height: auto;         
                        @media (max-width:$xsmall) {
                            width: 30px;
                        }           
                    }
                    .border{
                        position: absolute;                    
                        width: 100%;
                        height: 100%;
                        // border: 1px dashed;
                        border-radius: 50%;
                        padding: 10px;
                        box-sizing: content-box;
                        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='black' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 100px;
                    }
                }
            }            

            .content{
                .title-32{
                    margin-top: 20px;
                    margin-bottom: 50px;
                }
                .subtitle-24{
                    margin: 80px 0 50px 0;
                }
            }                             
        }       
    }     
}