.home,
.page-template-page-work,
.single-portfolio{
    .work,
    .projects,
    .related{
        .underline{
            &:after{
                background-color: #D2D4FF;                                
            }            
        }
        
        .projects-wrapper{
            justify-content: space-between;            
            .columns{
                height: 100%;
                &:first-of-type{
                    .img-wrapper{
                        // margin-top: 0!important;
                    }
                }
            }

            .project-wrapper{
                height: 100%;                
                &:hover:not(.single){
                    .img-wrapper{
                        img{                            
                            transform: scale(1.1)!important;
                        }
                    }
                }
                &.featured{
                    .img-wrapper{
                        margin-top: 100px;
                        height: 100%;
                        @media (max-width: $xsmall){
                            margin-top: 80px;
                        }                        
                        img{
                            max-height: 800px;
                            object-fit: cover;
                            transition: all .3s $ease1;
                            @media (max-width: $xsmall){
                                height: 500px;
                            }
                        }                        
                    }
                }                                
                .img-wrapper{                    
                    &.even{                                                       
                        object-fit: cover;
                        margin-top: 100px;
                        @media (max-width: $xsmall){
                            margin-top: 80px;
                        }
                        img{
                            height: 705px;
                            transition: all .3s $ease1;
                            @media (max-width: $xsmall){
                                height: 400px;                                
                            }
                        }
                    }
                    &.odd{                        
                        @media (max-width: $xsmall){
                            margin-top: 80px;
                        }
                        img{
                            object-fit: cover;
                            height: 690px;
                            width: 100%;
                            transition: all .3s $ease1;
                            @media (max-width: $xsmall){
                                height: 500px;
                            }
                        }
                    }                    
                }                
                .post-info{
                    display: flex;
                    flex-direction: revert;
                    align-items: center;
                    margin-top: 10px;
                    // p.caption{
                    //     border: 1px solid rgba($black, .12);
                    //     padding: 10px 15px;
                    //     margin-left: 20px;
                    //     // @include font-size(14px);
                    // }
                }
                // .post-tags{
                //     display: flex;
                //     flex-direction: row;
                //     .text-20{
                //         margin-right: 10px;
                //         color: rgba($black, .64);
                //         // @include font-size(20px);
                //         &:after{
                //             content: ',';
                //         }
                //         &:last-of-type{
                //             &::after{
                //                 content: '';
                //             }
                //         }
                //     }
                // }
            }
        }        

    }
}