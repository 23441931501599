.home{
    .services{
        h5.underline{
            // margin-bottom: 160px;
            &:after{
                background-color: #BDFFE7;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                top: -5px;
                @media (max-width:$xsmall) {
                    width: 40px;
                    height: 40px;
                    right: 0;
                    left: auto;
                }
            }
        }
        .section-content{
            .column{
                @media (max-width: $xsmall){
                    // margin-bottom: 80px;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }                
            }
        }        
        .img-wrapper{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;            
            margin-bottom: 50px;
            width: 120px;
            height: 120px;
            overflow: inherit;
            @media (max-width: $xsmall){
                width: 80px;
                height: 80px;
                margin-bottom: 20px;
            }
        }
        .pop{
            width: 120px;
            height: 120px;
            border-radius: 50%;            
            background-color: #F9FAFF;
            z-index: -1;
            @media (max-width: $xsmall){
                width: 80px;
                height: 80px;
            }
        }
        // .icon{
        //     position: relative;
        //     display: inline-flex;
        //     margin-right: 20px;
        // }
        .title-32{
            display: inline-flex;
        }
        img{
            position: relative;
            height: 30px;
            width: auto;
            @media (max-width: $xsmall){
                height: 20px;
            }
        }
        .subtitle-24{
            margin-bottom: 45px;
            @media (max-width: $xsmall){
                margin-bottom: 20px;
            }
        }        
        .text-16{
            opacity: .64;
        }
        .stats{
            margin-top: 250px;
            padding: 100px 0;
            background-color: $black;
            display: none;
            @media (max-width: $xsmall){
                margin-top: 100px;
            }
            .row{
                width: 100%;
                justify-content: center;        
                .text-20{
                    text-align: center;
                    color: white;
                }                        
            }
            .counter{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 120px;
                
                .value{
                    // @include font-size(80px);
                    // font-weight: 600;
                    // line-height: 1.2;
                    color: white;
                }
                .content{
                    // font-weight: 400;
                    // @include font-size(40px);
                    color: white;
                }                
            }
        }

        .quote{
            margin-top: 350px;
            @media (max-width: $xsmall){
                margin-top: 100px;
            }
            
            .quote-wrapper{
                text-align: center;
                span{
                    
                    opacity: .84;
                    text-align: center;
                    line-height: 2;
                    &.check{
                        position: relative;
                        svg{
                            width: 65px;
                            height: 55px;
                            margin-bottom: 15px;
                            margin-left: 0px;
                            margin-right: -30px;
                            
                        }                        
                    }    
                    .upper{
                        position: absolute;
                        bottom: 75px;
                        width: max-content;
                        left: -40px;
                        font-family: "Indie Flower", cursive;
                        opacity: 0;
                        visibility: hidden;
                        line-height: 1;
                        @include font-size(52px);
                        @media (max-width: $small){
                            position: relative;
                            bottom: auto;
                            left: auto;
                        }
                    }                
                }
            }            
        }
    }
}