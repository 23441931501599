.single-portfolio{
    .reviews{
        
        .reviews-wrapper{
            background-color: $black;
            padding: 90px!important;
            .review-header{
                display: none;
            }
            .name, .content{
                opacity: .84;
            }
            .content p{
                margin: 0;
            }
            .company{
                opacity: .64;
            }
            p{
                color: white;                
            }            
            .info{
                margin-top: 40px;
            }
        }        
    }
}