.single-portfolio{
    .hero{
        padding-top: 250px;
        margin-bottom: 110px;
        h5{
            margin-bottom: 80px;
        }
        .post-info{
            display: inline-block;            
            p.caption{
                &:first-child{
                    margin-left: 0;
                }
            }
        }
        .post-tags{
            margin-bottom: 80px;
            p{
                display: inline-block;
            }
        }
        .full-img{
            min-height: 800px;
            img{
                height: auto;
                min-height: 800px;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}