.page-template-page-about-us{
  .hero{
    margin-bottom:100px;
    padding-top: 250px;
    @media (max-width:$xsmall) {
      padding-top: 150px;
      margin-bottom:0px;
    }
    .underline{
      // margin-bottom: 160px;
      &:after{
        background-color: #FFC7D4;
        right: auto;
        left: 0;
      }
    }
  }      
}