.page-template-page-about-us,
.single-portfolio{
  .reviews{    
    position: relative;
    &>.row{
      .columns{
        text-align: center;
        position: relative;        
        h5.underline{
          margin-bottom: 50px;
          text-align: center;
          display: inline-block;
          @media (max-width: $xsmall){
            margin-bottom: 0px;
          }
          &:after{
            background-color: #E0D8FF;
            top: 50%;
            left: 30%;
            bottom: auto;
            right: auto;
          }
        }
      }
    }
    
    .swiper-container{
      display: flex;
      svg{
        position: absolute;
        top: -50px;
        @media (max-width: $xsmall){
          display: none;
        }
      }
    }
    
    .swiper{
      max-width: 80%;

      @media (max-width: $xsmall){
        max-width: 100%;
      }      
      @media (max-width: $xsmall){
        margin-top: 20px;
      }

      .slide{
        @media (max-width: $xsmall){
          background-color: #F9FAFF;
          padding: 20px 40px;
          border-radius: 10px;
        }
        svg{
          display: block;
          transform: scale(.2);
          position: absolute;
          left: -41px;
          top: -35px;
          @media (min-width: $xsmall){
            display: none;
          }
        }
        .content{
          p{
            opacity: .84;
            margin-top: 50px;
            @media (max-width: $xsmall){
              @include font-size(12px);
            }
          }
          .subtitle-24{
            @media (max-width: $xsmall){
              margin-top: 0;
            }
          }          
        }
        .text-16{
          opacity: .84;
          @media (max-width: $xsmall){
            @include font-size(12px);
            opacity: .44;
          }
        }
        .text-20{
          @media (max-width: $xsmall){
            @include font-size(14px);            
          }
        }
        
      }
    }
  }
}

.page-template-page-about-us .reviews{
  .pagination{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 50px;
    .swiper-pagination-bullet{
      width: 50px;
      height: 4px;
      background-color: rgba($black, .3);
      display: block;
      transition: all .3s $ease1;
      &.swiper-pagination-bullet-active{
        background-color: rgba($black, 1);
      }
    }
  }

}
