.single-services{
    .continuos-dev{
        margin-bottom: 200px;
        .content-wrapper{
            margin-top: 90px;
            justify-content: center;
        }
        .continuos-wrapper{
            padding: 150px 0;
            background-color: $black;
            position: relative;
        }
        hr{
            background-image: linear-gradient(to right, white 20%, rgba(0, 0, 0, 0) 20%);
            background-position: 50% 50%;
            background-size: 18px 36px;
            background-repeat: repeat-x;
            border-width: 0;                
            width: 100%;
            height: 1px;
            // margin-top: 80px;
            position: absolute;      
            z-index: 0;              
            top: 56%; 
            &:first-of-type{

            }
            &:last-of-type{
                display: none;
                @media (max-width:$small) {
                    display: block;
                    bottom: 295px;
                    top: auto;
                }
                @media (max-width:$xsmall) {
                    bottom: 283px;
                }
            }
            @media (max-width:$small) {                
                top: 400px;
            }
            @media (max-width:$xsmall) {
                top: 365px;
            }
        }
        h5{
            color: white;
            margin-bottom: 140px;
        }
        .cont-stages{
            display: flex;
            // align-items: center;
            justify-content: space-between;            
            @media (max-width:$small) {
                flex-wrap: wrap;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;    
                // align-items: center;
                
            }            
            .stage-wrapper{
                z-index: 1;
                &:nth-child(3n){
                    @media (max-width:$small) {
                        justify-content: flex-end;
                    }                
                }
                @media (max-width:$small) {
                    margin: 20px 0;                
                    width: 33%;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                }
                @media (max-width:$xsmall) {
                    width: 26%;
                }
                .stage{
                    @media (max-width:$small) {                     
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .icon{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        min-width: 160px;
                        min-height: 160px;
                        width: 160px;
                        height: 160px;
                        @media (max-width: $medium) {
                            width: 100px;
                            height: 100px;
                            min-width: 100px;
                            min-height: 100px;
                        }
                        @media (max-width: $small) {
                            width: 80px;
                            height: 80px;
                            min-width: 80px;
                            min-height: 80px;
                        }
                        img{
                            width: 45px;
                        }
                    }
                    .subtitle-24{
                        color: white;
                        text-align: center;
                    }
                    .title-40{
                        color: rgba(255,255,255,.32);
                        text-align: center;
                    }
                }
            }            
        }
        .card-wrapper-icon{
            margin-top: 100px;
            .card{
                .icon-wrapper{
                    .icon-container{
                        background-color: #BDFFE7!important;
                    }
                }
                .block-content{
                    padding: 0 40px;
                    @media (max-width: $small) {
                        padding: 0;
                    }
                }
            }
        }
    }
}