.page-template-page-services{
    .main-header{
        z-index: 1;
        background-color: white;
        transition: all .5s $ease1;
        &.services-hero{
            background-color: transparent;            
            .logo{
                path{
                    fill: white!important;
                    mix-blend-mode: normal!important;
                    transition: all .5s $ease1;
                }
                
            }
            .header-menu{
                .menu .menu-item{
                    &::after{
                        background-color: white;
                        transition: all .5s $ease1;
                    }
                    
                    a{
                        color: white;
                        transition: all .5s $ease1;
                    }
                    .sub-menu{
                      li{
                        &:after{
                          background-color: $black;
                          transition: all .5s $ease1;
                        }
                      }
                      a{
                        color:$black;
                      }
                    }
                }
                            
            }
            .burguer{
                span{
                    background-color: white;
                }
            }    
        }                    
    }
    .hero{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: $black;
        overflow: hidden;
        position: relative;        
        h5{
            color: white;
            div{
              display: inline-block;
            }
        }        
        .blob{
            width: 140px;
            height: 140px;
            background-color: #4535FF;
            position: absolute;
        }         
    }
}