.single-portfolio{
    .features{
        .features-group{
            margin-bottom: 80px;
        }
        .text-20{
            display: flex;
            align-items: flex-start;
            position: relative;
            margin-bottom: 25px;
            // &:before{                
            //     content: '';
            //     width: 5px;
            //     height: 5px;
            //     position: relative;
            //     border-radius: 50%;
            //     left: 0;
            //     top: 0;
            //     background-color: $black;
            //     margin-right: 10px;
            //     margin-top: 17px;
            // }
        }        
        .caption-20{
            margin-bottom: 20px;            
        }
        ul{
            list-style: disc;
            list-style-position: inside;
            ul{
                list-style: circle;
                padding-left: 10px;
                list-style-position: inherit;
                li{
                    line-height: 1.8rem;
                }
            }
        }
    }
}