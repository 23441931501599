:root{
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}
body{
  overflow-x: hidden;    
}

h1, h2, h3, h4, h5, p{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    font-style: normal;
}
#smooth-content{
    position: relative;
}
b{
  font-weight: bold;
}
h1{
    font-style: normal;
    font-weight: 700;
    @include font-size(92px);
    line-height: 1.2em;
    color: $black;
    position: relative;
    display: inline-block;
    @media (max-width: $xsmall) {
        font-size: 32px;
    }
    &.underline{
        --width: 0px;
        &:after{
            content: '';
            position: absolute;            
            width: var(--width);
            height: 42px;
            background-color: $black;
            top: 55px;
            right: 10px;
            z-index: -1;
            @media (max-width: $xsmall) {
                height: 16px;
            }
        }
    }
    &.underline.round-underline{
        --scale: 0;
        width: inherit;
        &:after{
            width: inherit;
            transform: scale(var(--scale));
        }
    }
}
h2{
    font-style: normal;
    font-weight: 300;
    @include font-size(92px);
    line-height: 1.2em;
    color: $black;

    position: relative;
    display: inline-block;        
}
h3{
    font-style: normal;
    font-weight: 600;
    @include font-size(72px);
    line-height: 1.8em;
    color: $black;
    position: relative;    
}
h4{
    font-style: normal;
    font-weight: 300;
    @include font-size(72px);
    line-height: 1.4em;
    color: $black;
    position: relative;
}
h5{
    font-style: normal;
    font-weight: 600;
    @include font-size(56px);
    line-height: 1.2em;
    color: $black;
    position: relative;
    @media (max-width: $medium) {
      @include font-size(40px);
      line-height: 1.5;
  }
    @media (max-width: $small) {
        @include font-size(24px);
        font-weight: 500;
        line-height: 2;
    }
    &.underline{
        --width: 0px;
        &:after{
            content: '';
            position: absolute;
            width: var(--width);
            height: 24px;
            background-color: $black;
            bottom: 5px;text-align: center;
            right: -25px;
            z-index: -1;
            @media (max-width: $xsmall){
                bottom: 0;
                left: 0;
                top: auto;
                right: auto;
                height: 16px;
            }
        }
    }
    &.underline.round-underline{
        --scale: 0;
        width: inherit;
        &:after{
            width: inherit;
            transform: scale(var(--scale));
        }
    }
}
h6{
    font-style: normal;
    font-weight: 300;
    @include font-size(56px);
    line-height: 84px;
    color: $black;
}
.title-40{
    font-style: normal;
    font-weight: 600;
    @include font-size(40px);
    line-height: 1.3em;
    color: $black;
}
.title-40-light{
    font-style: normal;
    font-weight: 300;
    @include font-size(40px);
    line-height: 1.6em;
    color: $black;
    @media (max-width: $xsmall){
      @include font-size(16px);
      line-height: 2em;
    }
}
.title-32{
    font-style: normal;
    font-weight: 600;
    @include font-size(32px);
    line-height: 1.2em;
    color: $black;
}
.title-32-light{
    font-style: normal;
    font-weight: 300;
    @include font-size(32px);
    line-height: 1.2em;
    color: $black;
}
.subtitle-24{
    font-style: normal;
    font-weight: 500;
    @include font-size(24px);
    line-height: 2em;
    color: $black;
    @media (max-width: $small) {
      @include font-size(16px);      
  }
}
.subtitle-20{
    font-style: normal;
    font-weight: 500;
    @include font-size(20px);
    line-height: 2em;
    color: $black;
}
.text-20{
    font-style: normal;
    font-weight: 300;
    @include font-size(20px);
    line-height: 2em;
    color: $black;
    text-align: left;
    @media (max-width: $xsmall){
        font-size: 14px;
    }
}
.text-16{
    font-style: normal;
    font-weight: 300;
    @include font-size(16px);
    line-height: 2em;
    color: $black;
    text-align: left;
    @media (max-width:$xsmall) {
        line-height: 1.5em;
    }
}
.text-14{
  font-style: normal;
  font-weight: 300;
  @include font-size(14px);
  line-height: 1.2em;
  color: $black;
  text-align: left;
  @media (max-width:$xsmall) {
      line-height: 1em;
  }
}
p{
    font-style: normal;
    font-weight: 300;
    @include font-size(20px);
    line-height: 2em;
    color: $black;
    text-align: left;
    @media (max-width: $xsmall){
        @include font-size(14px);
        // line-height: 1.5;
    }
}
strong{
    font-style: normal;
    font-weight: bolder;
    @include font-size(20px);
    line-height: 2em;
    color: $black;
    text-align: left;
    @media (max-width: $xsmall){
        font-size: 14px;
    }
}
ul,ol {
    li{
        font-style: normal;
        font-weight: 300;
        @include font-size(20px);
        line-height: 2em;
        color: $black;
        text-align: left;
        list-style-position: inside;
        @media (max-width: $xsmall){
            font-size: 14px;
        }
        &>*{
          // display: inline-block;
        }
    }
}
.section-title{
    margin-bottom: 25px;
    display: flex;
    // align-items: center;
    @media (max-width: $xsmall){
        flex-direction: row;        
    }
}
.section-content{
    margin-top: 60px;
    @media (max-width: $xsmall) {
        margin-top: 0;
    }
}

strong{
    font-weight: bold;
}

span{    
    font-weight: 500;    
}
label{    
    font-weight: 300;
}
a{    
    font-weight: 500;
    text-decoration: none;
    color: #215383;
}
section{
    margin-bottom: 360px;
    @media (max-width: $small){
        margin-bottom: 100px;
    }
}
.hide-small{
    display: inherit;
    @media (max-width: $small){
        display: none;
    }
}
.show-small{
    display: none;
    @media (max-width: $small){
        display: block;
    }
}

// main{
//     height: 100%;
//     &>div{
//         height: 100%;
//     }
// }

button{
    border: none;
    color: white;    
    background-color: transparent;    
    @include font-size(20px);
    font-weight: 600;
    line-height: 1.2;
    max-width: 245px;
    transition:  all .5s $ease1;        
    @media screen and (max-width: $small) {
        @include font-size(16px);
    }
    &[type="submit"]{
        padding: 20px 50px;
    }
    &:hover{
        transform: scale(1.03);
    }
    a{
        color: white;   
        padding: 20px 50px;
        background-color: $black;
        border-radius: 8px; 
        border: none;
        display: block;
        @media (max-width: $xsmall) {
            padding: 10px 20px;
        }
    }
}
button.small{    
    @include font-size(14px);
}
.caption-20{
    font-style: normal;
    font-weight: 400;
    @include font-size(20px);
    line-height: 2em;
    text-transform: uppercase;
    color: rgba($black, .64);    
}
.caption{
    font-style: normal;
    font-weight: 400;
    @include font-size(14px);
    line-height: 1.2;
    color: #000000;
}

.see-all{
    display: inline-flex;
    align-items: center;    
    &.underline{
        --width: 0px;
        &:after{
            content: '';
            position: absolute;            
            width: var(--width);
            height: 22px;
            background-color: #BDFFE7;
            top: 13px;
            right: 10px;
            z-index: -1;
            @media (max-width: $xsmall) {
                height: 16px;
            }
        }
    }
    @media (max-width: $xsmall){
        // margin-top: 40px;           
    }
    a{
        display: inline-flex;
        align-items: center;
        line-height: 0;
        @include font-size(20px);
        margin-left: 50px;
        
        span{
            height: 100%;
            display: inline-block;
            line-height: 0;
            &.arrow{
                margin-left: 5px;
                transition: all .3s $ease1;
            }
            &.circle{
                transform: translateX(-15px);
            }
        }
        &:hover{
            .arrow{
                transform: translateX(5px);
            }
        }
    }     
}

//IMG ANIMATION
.img-wrapper{
    position: relative;
    overflow: hidden;    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;                    
    }
    .mask{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #D2F7FF;
        width: 100%;
    }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
.load-screen{
    img{        
        width: 50px;
        height: 50px;
        animation:spin 1s linear infinite;
    }
}

p.caption{
    border: 1px solid rgba($black, .12);
    padding: 10px 15px;
    margin-left: 20px;
    text-align: center;
    // @include font-size(14px);
}

.post-tags{
    display: flex;
    flex-direction: row;
    .text-20{
        margin-right: 10px;
        color: rgba($black, .64);
        // @include font-size(20px);
        &:after{
            content: ',';
        }
        &:last-of-type{
            &::after{
                content: '';
            }
        }
    }
}

//TEMP
.awsm-job-main{
    padding: 150px 10px 0 10px;

    @media (max-width:$xsmall) {
        padding-top: 50px;
    }
}
.awsm-job-wrap{
    margin-top: 50px;
}
.awsm-filter-wrap form{
    display: none;
}
.awsm-job-single-wrap{
    margin-top: 150px;
    @media (max-width: $xsmall) {
        margin-top: 50px;
    }
}
.awsm-job-form{
    // display: none;
}
.post-edit-link{
    display: none;
}
.single-awsm_job_openings .column{
    padding: 0;
}
.awsm_job_spec_below_content{
    display: none;
}

.awsm-job-single-wrap.awsm-col-2 .awsm-job-content{
    width: 100%!important;
    float: none!important;
    padding-right: 0!important;
}

.awsm-job-form-inner h2{
    font-size: 20px;
}
.awsm-job-form-control{
    height: 35px;
    border: 1px solid rgba(0,0,0,.5)
}

.awsm-job-form-control[type="file"]{
    border: none!important
}
.awsm-job-form-inner{
    border: none!important;
}
.awsm-job-form-options-container label, .awsm-job-inline-group label{
    font-weight: 300!important;
    font-size: 12px!important;
}

.awsm-job-form-group input[type="submit"]{
    width: 200px;
    height: 25px;
}