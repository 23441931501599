.home{
  .hero{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;      
    margin-bottom: 0; 
    padding: 120px 0;
    h1{
      &:after{
        background-color: #D2F7FF;
        right: 0;
        left: 0;
        @media screen and (max-width: $small) {
          left: 0;
          right: auto;
          bottom: 0;  
          top: auto;       
        }
      }
    }  
    p{
      margin: 75px 0;
    }        
    .row{
      width: 100%;
    }
  }    
}