.single-services{
  .publishers{
    margin-bottom: 100px;
    .row{
      justify-content: space-between;
    }
    .title-40{
      text-align: center;
      margin: 150px 0 100px 0;
    }
    .img-block{
      width: 100%;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
    }
    .img-wrapper{
      display: inline-flex;
      justify-content: center;
      margin: 0 auto;
      overflow: inherit;    
      margin-bottom: 50px;  
      img{
        width: 90px;
        height: auto;
        position: relative;        
      }
      span.color{
          width: 70px;
          height: 70px;
          position: absolute;
          right: -10px;
          background-color: #000;
          border-radius: 50%;      
      }
    }
    .text-16{
      text-align: center;
      color: rgba($black, .84);
    }
    .subtitle-20{
      text-align: center;
    }
  }
}