.page-template-page-careers{
  h5{
    margin-bottom: 20px;
    &.underline{
      display: inline-block;
      &:after{
        background-color: #FFC7D4;
        bottom: 0px;
        right: 0;
      }
    }    
  }
  a{
    color: $black;
  }
  .hero{
    padding-top: 180px;
    margin-bottom: 150px;
    // .underline{
    //   &:after{                
    //     background-color: #C6D6FF;
    //     top: 35px;
    //     left: 117px;
    //     @media (max-width: $xsmall) {
    //       left: 0;
    //       top: 10px;
    //     }
    //   }
    // }
  }
  .benefits{
    text-align: center;
    margin-bottom: 150px;
    h5.underline{
      text-align: center;
      display: inline-block;
      text-align: center;
      margin-bottom: 40px;
      // margin-bottom: 130px;
      &:after{
        background-color: #FFC7D4;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        top: -5px;
        @media (max-width:$xsmall) {
            width: 40px;
            height: 40px;
            right: 0;
            left: auto;
        }
      }
    }
    .wrapper{
      column-gap: 1rem;
      row-gap: 2rem;
      display: flex;        
      flex-flow: wrap;
      justify-content: center;
      .benefit{
        position: relative;
        flex-basis: calc(33.33% - 1rem);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 33%;

        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        padding: 25px 40px;

        overflow: hidden;        
        @media (max-width:$small) {
          flex-basis: calc(50% - 1rem);
          max-width: 50%;
        }
        @media (max-width:$xsmall) {
          flex-basis: 100%;
          max-width: 100%;
        }
        .subtitle-24{
          line-height: 1.2;
          margin-bottom: 20px;
        }
        .text-16{
          color: rgba($black, .64);
        }
        span{
          background-color: #ffc7d4;
          position: absolute;
          bottom: -15px;
          right: -15px;            
          border-radius: 50%;
          z-index: -1;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            height: 40px;
          }
        }
      }
    }
  }
  .careers-wrapper{
    margin-bottom: 100px;
    h5{
      margin-bottom: 60px;
    }
    // .whr-item{
    //   margin-bottom: 20px;
    //   h3.whr-title{
    //     @include font-size(25px);
    //     color: red;
    //     line-height: 1;
    //     &:after{
    //       background-color: red;
    //       top: 50%;
    //       left: 10px;
    //       bottom: auto;
    //       right: auto;
    //     }
    //   }
    //   .whr-info{
    //     .whr-location{
    //       @include font-size(14px);
    //     }
    //     .whr-date{
    //       display: none;
    //       @include font-size(14px);
    //     }
    //   }
    // }
    .filter{
      span{
        border: 1px solid rgba($black,.18);
        margin-right: 5px;
        color: $black;
        font-weight: 300;
        padding: 10px 20px;
        cursor: pointer;
        &.active,
        &.hover{
          color: white;
          background-color: $black;
          font-weight: 600;
        }
      }
    }
    .jobs{
      margin-top: 50px;      
      .see-all{
        margin-top: 20px;
        position: relative;
        &.underline{
          &:after{
            background-color: #FFC7D4;
          }
        }
        a{
          margin-left: 5px;
        }
      }
      hr{
        margin: 40px 0;
      }
      .position{        
        display: block;
        &:nth-of-type(1){
          padding-top: 0;
        }
      }
      .job-type{
        margin-top: 10px;
        display: flex;
        align-items: center;
        img{
          width: 20px;
          margin-right: 10px;
          &:nth-of-type(2){
            margin-left: 20px;
          }
        }
        span{
          color: rgba($black, .84);          
        }
      }
    }
  }
} 