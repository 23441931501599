.services-list{
    .row{
        padding-top: 80px;
        padding-bottom: 45px;
        border-bottom: 1px solid rgba($black, .12);
        @media (max-width: $small){
            padding-top: 50px;
            padding-bottom: 50px;            
        }
        &:first-of-type{
            padding-top: 0;
        }
        &:last-of-type{
            padding-bottom: 0;
            border-bottom: none;
        }
        &.even{
            justify-content: flex-start;            
        }
        &.odd{
            justify-content: flex-end;
        }
    }        
    .service-wrapper{
        display: inline-flex;
        align-items: flex-start; 
        @media (max-width: $small){
            flex-direction: column;
        }           
        &:first-child{
            padding-top: 0;
        }
        &:last-child{
            padding-bottom: 0;
        }
        h5{
            margin-right: 80px;
            color: #4535FF;
            @media (max-width: $small){
                margin-right: 0;
            }
        }
        .icon{
            position: relative;
            display: inline-flex;
            margin-right: 20px;
            img{
                position: relative;
                height: 30px;
                width: auto;
                @media (max-width: $xsmall){
                    height: 20px;
                }
            }
        }
        .title-32{
            display: inline-flex;
        }
        .content{
            // margin-bottom: 50px;
            margin-top: 15px;
            @media (max-width: $small){
                margin-bottom: 0;
            }
            .title-32{
                margin-bottom: 50px;
                @media (max-width:$xsmall) {
                    margin-bottom: 20px;
                }
            }
            .text-20{
                margin-bottom: 55px;
                opacity: .84!important;
                @media (max-width: $small){
                    margin-bottom: 0px;
                }
            }
            .see-all{
                margin-top: 50px;
                .text-20{
                    margin-bottom: 0;
                }
                a{
                    margin-left: 0;
                }
            }
            .areas{
                .text-16{
                    margin: 20px 0;
                    @media (max-width: $small){
                        margin: 10px 0;
                    }
                    &::before{
                        opacity: 1;
                        content: '';
                        width: 5px;
                        height: 5px;
                        background-color: $black;
                        border-radius: 50%;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 10px;
                    }
                }                
            }
        }            
    }
}