.page-template-page-services,
.single-services{    
    .tech{
        background-color: #F9FAFF;
        padding: 100px;        
        .section-title{
            justify-content: center;
        }
        .text-20{
            text-align: center;
        }
        .underline{
            &:after{
                background-color: #C6D6FF;
            }                        
        }

        .stack{
            margin-top: 65px;
            justify-content: center;
            .column{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 50px 0;                
            }
            img{
                max-width: 80px;
                height: auto;
                max-height: 80px;
                transition: all .3s $ease1;
                @media (max-width: $xsmall) {
                    max-width: 55px;
                }
                &:hover{
                    transform: scale(1.2);
                }
            }
        }
    }
}