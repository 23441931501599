.page-template-page-blog{
  .hero{
    padding-top: 250px;
    margin-bottom: 100px;
    @media (max-width: $small) {
      padding-top: 150px;
    }
    h5{
      display: inline-block;
      margin-bottom: 40px;
      &:after{                
        background-color: #C6D6FF;
        top: 35px;
        right: 0;
      }
    }
  }
  .blog-list{
    .wrapper{
      column-gap: 1.5rem;
      row-gap: 1.5rem;
      display: flex;        
      flex-flow: wrap;
      justify-content: center;
      .post{
        position: relative;
        flex-basis: calc(33.33% - 1.5rem);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // max-width: 33%;     
        @media (max-width:$medium) {
          flex-basis: calc(50% - 1.5rem);
          height: auto;
        }
        @media (max-width:$small) {
          flex-basis: calc(100%);
          height: auto;
        }   
        img{
          width: 100%;    
          height: 100%;
          aspect-ratio: 16/9;
          border-radius: 8px;
          object-fit: cover;
        }
        .subtitle-24{
          line-height: 1.2;
          margin-top: 10px;
        }                 
      }
    }
  }
}