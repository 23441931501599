.home{
    .about{
        .underline{
            &:after{
                background-color: #FFC7D4;
            }
        }
        .row.section-content{
            width: 100%;
            justify-content: space-between;
            align-items: center;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .text-20{
                @media (max-width: $small){
                    margin-top: 20px;
                }
            }
            h4{
                margin-top: 60px;
                @media (max-width: $small){
                    margin-top: 0px;
                }
                // @include font-size(72px);
                // font-weight: 300;
                // line-height: 1.7;
            }
        }
    }
}