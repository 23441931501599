.single-post{
  article{
    padding-bottom: 100px;
    padding-top: 200px;
    display: block;
    position: relative;
    @media (max-width: $small) {
      padding-top: 150px;
    }

    .hero{
      img{
        width: 100%;
        height: auto;
      }
    }

    h1{
      @include font-size(80px);
      line-height: 1.2em;
      margin-bottom: 50px;
      font-weight: 700;
      &:after{
        background-color: #D2F7FF;
        right: 0;
        left: 0;
        bottom: 0;
        top: auto;
      }
    }
    h2{
      @include font-size(50px);
      line-height: 1.2em;
      font-weight: 500;
      margin: 10px 0 5px 0;
    }
    h3,h4,h5{
      @include font-size(30px);
      line-height: 1.2em;
      font-weight: 500;
      margin: 10px 0 5px 0;
      display: inline-block;
    }
    strong{
      font-size: inherit;
      line-height: inherit;
    }
    p{
    line-height: 1.5;
    font-style: normal;
    }
    a{
      text-decoration: underline;
    }
    img{
      margin: 0 auto;
      width: auto;
      height: auto;
      max-width: 100%;
      display: block;
    }
  }
  .hero{
    margin-bottom: 50px;
    span{
      margin: 10px 0;
      display: block;
    }
  }
}