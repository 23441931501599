.main-footer{    
    background-color: $black;
    padding-top: 310px;
    padding-bottom: 100px;
    @media (max-width: $xsmall){
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .footer-wrapper{
        &>.row{
            align-items: center;
            .form-wrapper{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }        
    }
    p,span{
        color: white;   
    }
    .title-40-light{        
        line-height: 1.5;
        color: white;
    }
    h3{
        // @include font-size(68px);
        // font-weight: 700;
        line-height: 1.3;
        color: white;
    }
    #footer-form{
        display: flex;
        width: 60%;        
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        @media (max-width: $xsmall){
            margin-top: 50px;
            width: 100%;
        }
        .form-input{
            position: relative;
            width: 100%;
            margin-bottom: 65px;
            &:last-of-type{
                margin-bottom: 0;
            }
            label{
                position: absolute;
                top: 10px;
                @include font-size(18px);
                font-weight: 300;
                display: block;
                color: white;       
                opacity: .72;
                transition: all .3s $ease1;
            }
            
            input[type="text"],
            input[type="email"],
            textarea{                
                height: 40px;
                width: 100%;
                background-color: $black;
                border: none;
                border-bottom: 1px solid rgba(white, .72);     
                outline: none;      
                color: white;    
                font-family: 'Poppins';
                @include font-size(18px);
                color: white;          
                // &:not(:placeholder-shown) {
                //     /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
                //     border-color: red;
                // }           
                // &:placeholder-shown {
                //     /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
                //     border-color: green;
                // }           
            }
            button[type="submit"]{
                width: 100%;                
                background-color: #391EDB;
                border-radius: 8px;
                max-width: inherit;
                cursor: pointer;
                // pointer-events: none;
            }

            &.active{
                label{
                    top: -20px;
                }
            }            
        }
    }
    .email-notification{
        opacity: 0;
        visibility: hidden;
        position: absolute;        
        justify-content: center;
        &.success{
            color: #00E886;
        }
        &.error{
            color: #E70C3A;
        }
    }
    .contacts{
        margin-top: 120px;
        @media (max-width: $xsmall){
            margin-top: 80px;
        }
        a{
          color: white;
        }
        .subtitle-24{
            margin-bottom: 45px;
        }
        &>div{
            display: flex;
            align-items: center;
            margin-bottom: 35px;
            @media (max-width: $xsmall){
                margin-bottom: 25px;
            }
        }        
        .contact-icon{
            height: 24px;
            width: auto;
            margin-right: 30px;
            opacity: .84;
        }        
    }
    
    .bottom-bar{
        margin-top: 250px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;        
        .columns{
            text-align: center;
            &:first-of-type{
                text-align: left;
                @media (max-width: $xsmall){
                    text-align: center;
                }
                p{
                    @media (max-width: $xsmall){
                        text-align: center;
                    } 
                }
            }
            &:last-of-type{
                text-align: right;                                   
                @media (max-width: $xsmall){
                    text-align: center;
                    margin: 0px 0 20px 0;
                }
            }
            .social-icon{
                height: 24px;
                width: auto;
            }
        }            
        .social{
            margin: 0 20px;
            @media (max-width: $xsmall){
                margin: 20px;                
            } 
            &:last-of-type{
                margin-right: 0;
            }
            &:first-of-type{
                margin-left: 0;
            }
        }        
        .small-hidden{
          @media (max-width: $small) {
            display: none;
          }
        } 
        .footer-menu{
          ul{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              @media (max-width: 385px) {
                  flex-direction: column;
              }
              li{
                list-style: none;      
                list-style-position: inherit;
                  a{
                      color: white;
                      @include font-size(16px);
                      font-weight: 300;                        
                      margin: 0 10px;                      
                  }
                  &:after{
                      content: '.';
                      color: white;                        
                      @include font-size(32px);    
                      @media (max-width: 385px) {
                          content: '';
                      }             
                  }
                  &:last-of-type{
                      &:after{
                          content: '';
                      }
                  }
              }
          }
        }
    }
}