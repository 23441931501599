.page-template-page-work{
    .hero{
        padding-top: 250px;
        margin-bottom: 100px;
        @media (max-width: $xsmall) {
            padding-top: 150px;
            margin-bottom: 0px;
        }
        .underline{            
            // margin-bottom: 160px;
            &:after{                
                background-color: #C6D6FF;
                top: 35px;
                left: 217px;
                @media (max-width: $xsmall) {
                    left: 0;
                    top: 10px;
                }
            }
        }             
    }    
}