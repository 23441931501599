.page-template-page-about-us .team{
  margin-bottom: 0;
  .swiper-content{
    position: relative;
    .swiper{
      cursor: pointer;
      // margin-top: 280px;
      max-width: calc(100% - 150px);
      @media (max-width: $small){
          margin-top: 80px;          
      }
      @media (max-width: $xsmall){
        max-width: 100%;
      }
      .slide{
        box-shadow: 0px 0px 12px 8px rgba(152, 152, 152, 0.04);
        margin: 20px;
        border-radius: 12px;        
      }
      .img-wrapper{                        
        img{
          object-fit: contain;
        }
      }
      .info{
        background-color: #F9FAFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px 25px;
        .text-20{
          text-align: center;
          line-height: 1.2;          
        }
        .subtitle-24{
          text-align: center;
          line-height: 1.2em;
          margin-bottom: 10px;
        }
      }
    }    
  }
  

  .quote{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 250px 0;
    @media (max-width: $small){
        padding: 80px 0;
    }
    p{
      text-align: center;
    }
  }
  .world{
    // margin-bottom: 150px;
    .underline{
      margin-bottom: 50px;
      &:after{
        background-color: #D2F7FF;
        left: 0;
        right: auto;
      }
    }
  }

  .globe{
    // background-color: $black;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.next.see-all,
.prev.see-all{
  &.underline{
    --width: 0px;
    &:after{
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #BDFFE7;
      bottom: 5px;
      right: -25px;
      z-index: -1;
      // @media (max-width: $xsmall){
      //   bottom: 0;
      //   left: 0;
      //   top: auto;
      //   right: auto;
      //   height: 16px;
      // }
    }
  }
  &.round-underline{          
    --scale: 0;
    // width: inherit;
    &:after{
      border-radius: 50%;            
      // width: inherit;
      transform: scale(var(--scale));
    }
  }
}
.prev.see-all{
  left: 0px;
  position: absolute;
  top: 50%;        
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;     
  @media (max-width: $xsmall){
    display: none;
  }
  &:hover{
    a{
      .arrow{
        transform: translateX(-5px);
      }
    }
  }
  &.see-all{
    a{        
      left: 30px;
      top: -8px;
      display: block;
      position: relative;
    }
  }
  a{
    margin-left: 0;
    span.arrow svg{
      transform: rotate(180deg);
    }
    span.circle{
      transform: translateX(15px);
    }
    &:hover{
      .arrow{
        transform: translateX(-5px);
      }
    }
  }   
  &.underline{
    --width: 0px;
    &:after{
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #BDFFE7;
      top: auto;
      bottom: -12px;
      right: auto;
      left: 3px;
      z-index: -1;
      // @media (max-width: $xsmall){
      //   bottom: 0;
      //   left: 0;
      //   top: auto;
      //   right: auto;
      //   height: 16px;
      // }
    }
  }
}
.next.see-all{
  right: 0px;
  position: absolute;
  top: 50%;        
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;       
  @media (max-width: $xsmall){
    display: none;
  }
  &:hover{
    a{
      .arrow{
        transform: translateX(5px);
      }
    }
  }
  &.see-all{
    a{
      left: -30px;
      top: -8px;
      display: block;
      position: relative;        
    }
  }
  a{
    margin-left: 0;
  } 
  &.underline{
    --width: 0px;
    &:after{
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #BDFFE7;
      top: auto;
      bottom: -12px;
      left: auto;
      right: 3px;
      z-index: -1;
      // @media (max-width: $xsmall){
      //   bottom: 0;
      //   left: 0;
      //   top: auto;
      //   right: auto;
      //   height: 16px;
      // }
    }
  }
}    