.page-template-page-about-us{    
  .history{    
    .row{
      justify-content: space-between;
    }    
    h5{
      // margin-bottom: 160px;
      display: inline-block;      
      @media (max-width: $small){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:after{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 50px;
          height: 2px;
          background-color: $black;          
        }
      }      
    }  
    .divider{
      top: 10px;
      display: block;
      position: relative;
      height: 50px;
      width: 2px;
      background-color: #1B1B21;
      @media (max-width: $small){
        display: none;
      }
    }
    img{
      width: 100%;
      height: auto;
      object-fit: contain;
      margin-top: 120px;
      @media (max-width: $xsmall) {
          margin-top: 50px;
      }
    }
    p{
      overflow: hidden;
      @media (max-width: $small){
        margin-top: 50px;
      }
      // height: ;
    }    
    .elipsis{
      margin-top: 20px;
      display: block;
    }  

    .see-all{
      display: inline-flex;
      align-items: center;
      margin-top: 50px;
      cursor: pointer;
      &.underline{
        &:after{
          background-color: #BDFFE7;
          @media (max-width: $xsmall){
            top: 33px;
          }
        }
      }
      span{
        height: 100%;
        display: inline-block;
        line-height: 0;
        pointer-events: none;
        &.arrow{
          margin-left: 5px;
          transition: all .3s $ease1;
        }
        &.circle{
          transform: translateX(-15px);
        }
      }
      &:hover{
        .arrow{
            transform: translateX(5px);
        }
      }   
    }    
  }
}