.single-services{
    .blob-wrapper{
        .blob{
            z-index: 0;
            // mix-blend-mode: inherit;
        }
    }
    .main-header{
        z-index: 1;
        background-color: white;
        transition: all .5s $ease1;
        &.services-hero{
            background-color: transparent;
            .logo{
                path{
                    fill: white;
                    mix-blend-mode: normal!important;
                    transition: all .5s $ease1;
                }
                
            }
          .header-menu{
              .menu .menu-item{
                  &::after{
                      background-color: white;
                      transition: all .5s $ease1;
                  }
                  
                  a{
                      color: white;
                      transition: all .5s $ease1;
                  }

                  .sub-menu{
                    li{
                      a{
                        color: $black;
                      }
                      &:after{
                        background-color: $black;
                        transition: all .5s $ease1;
                      }
                      &.current-menu-item{
                        a{
                          // background-color: transparent;
                          
                        }
                      }
                    }
                    a{
                      // color:$black;
                    }
                  }
              }              
          }
          .mobile-menu .header-menu .menu{
            .sub-menu{
              li{
                a{
                  color: white;
                }
                &.current-menu-item{
                  a{
                    background-color: transparent;   
                  }
                }
              }              
            }
          }
          .burguer{
              span{
                  background-color: white;
              }
          }
        }
    }
    .hero{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60vh;
        background-color: $black;
        overflow: hidden;
        position: relative;        
        margin-bottom: 0;
        flex-direction: column;
        .row{
          justify-content: center;
        }
        h5{
            color: white;
            text-align: center;
            margin-bottom: 20px;
        }  
        .text-20{
          text-align: center;
          color: rgba(white, .84);
        }      
        .blob{
            width: 140px;
            height: 140px;
            background-color: #4535FF;
            
        }
    }

    &.layout2{
        .main-header{
            background-color: white;            
            &.services-hero{
                background-color: transparent;
                .logo{
                    path{
                        fill: $black;
                    }
                }
                .desktop-menu .header-menu{
                    #menu-primary .menu-item{
                        &::after{
                            background-color: $black;
                        }
                        a{
                            color: $black;
                        }
                    }
                }
                .burguer{
                    span{
                        background-color: $black;
                    }
                }    
            }
            &.mobile-menu-active{
                .logo{
                    path{
                        fill: #fff!important;
                    }
                }
                .burguer{
                    span{
                        background-color: white;
                    }
                }
            }
        }        

        .hero{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60vh;
            background-color: #F9FAFF;
            overflow: hidden;
            position: relative;
            h5{
                color: $black;
            }
            .text-20{
              color: rgba($black, .84);
            }
        }
    }
}