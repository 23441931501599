.single-services{
    .features{
        
    }
    .phases{
        padding: 100px 0;
        margin-bottom: 50px;
        max-width: 100%;
        margin: 0;
        .phases-wrapper{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @media (max-width:$xsmall) {
                flex-direction: column;
            }
            .phase{
                display: inline-flex;
                flex-direction: column;
                // width: 160px;
                margin-top: 40px;
                @media (max-width:1500px) {
                    // width: 100px;                
                }
                &:last-of-type{
                    .column-wrapper{
                        span.arrow{
                            display: none;
                        }
                    }
                }
                // .column-wrapper{
                //     display: inline-flex;
                //     align-items: center;                
                    
                // }
                .subtitle-24{
                    text-align: center;
                    margin-top: 20px;
                }
                .icon-wrapper{
                    min-width: 160px;
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin: 0 auto;
                    @media (max-width:1500px) {
                        min-width: 100px;
                        width: 100px;
                        height: 100px;
                    }
                    img.icon{
                        height: 60px;
                        @media (max-width:1500px) {
                            height: 40px;
                        }
                    }
                    .border{
                        position: absolute;                    
                        width: 100%;
                        height: 100%;
                        // border: 1px dashed;
                        border-radius: 50%;
                        padding: 10px;
                        box-sizing: content-box;
                        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='black' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
                        border-radius: 100px;
                    }
                }                    
            }
            .arrow-container{
                margin-top: 90px;
                @media (max-width:1500px) {
                    margin-top: 60px;
                }
                @media (max-width:$xsmall) {
                    width: fit-content;
                    margin: 0 auto;
                    transform: rotate(90deg)!important;
                }                
                &:last-child{
                    display: none;
                }
                span.arrow{
                    display: block;
                    width: 50px;
                    height: 2px;
                    background-color: #FFC7D4;
                    margin: 30px 25px;
                    position: relative;
                    .diamond {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border: 5px solid transparent;
                        border-bottom-color: #FFC7D4;
                        // position: relative;
                        right: -1px;
                        top: -9px;
                        &:after{                    
                            content: '';
                            position: absolute;
                            left: -5px;
                            top: 5px;
                            width: 0;
                            height: 0;
                            border: 5px solid transparent;
                            border-top-color: #FFC7D4;
                        }
                    }        
                }                        
            }        
        }    
    }

    .phases-cont{
      margin-bottom: 0;
    }
    
    .phases-content-wrapper{    
        .phase-block{
            display: flex;
            margin-bottom: 50px;
            border: 1px solid rgba(27, 27, 33, 0.12);
            border-radius: 12px;
            overflow: hidden;
            position: relative;   
            @media (max-width:$small) {                
                flex-direction: column;
            }
            .counter{            
                min-width: 170px;
                min-height: 240px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;                
                @media (max-width:$small) {
                    position: relative;
                    min-height: inherit;
                    justify-content: start;
                }      
                .counter-bg{
                    min-width: 170px;
                    min-height: 240px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width:$xsmall) {
                        min-width: 100px;
                        min-height: 100px;
                    }
                    @media (max-width:$xxsmall) {
                        min-width: 50px;
                        min-height: 50px;
                    }
                }          
                p{
                    margin-left: 50px;
                }
            }
            .content{
                margin-left: 220px;
                padding: 100px 30% 45px 0;
                @media (max-width:$small) {
                    margin-left: 0;
                    padding: 50px;
                }                
                .title-32{
                    margin-bottom: 50px;
                }
                .text-20{                
                    p{
                        margin-bottom: 50px;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 300;
                        @include font-size(20px);
                        line-height: 2em;
                        color: #1B1B21;
                        text-align: left;
                    }
                }
                ul{
                    list-style: disc;
                    list-style-position: inside;
                    margin-bottom: 50px;
                    li{//text 16
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 300;
                        @include font-size(16px);
                        line-height: 2em;
                        color: $black;
                        text-align: left;
                        @media (max-width:$xsmall) {
                            line-height: 1.5em;
                        }
                    }
                }
            }
            .block-icon{
                height: 120px;
                width: auto;
                position: absolute;
                bottom: 20px;
                opacity: .1;
                right: 20px;                
            }
        }
        
    }

    .tech{
        margin-bottom: 0;
        @media (max-width:$small) {
            padding: 50px 0;
        }
    }
}
