.main-header{  
  position: absolute;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  width: 100%;    
  height: 120px;
  display: flex;
  align-items: center;
  z-index: 99!important;
  background-color: white;
  transition: all .5s $ease1;
  position: fixed;
  @media (max-width: $xsmall){
      // position: relative;
      height: 80px;
  }
  &.active{
      // height: 80px;
      // transform: translateY(-100%);
      @media (max-width: $xsmall){
          // position: relative;
          // height: 70px;
      }

      .logo{
          // width: 150px;
          @media (max-width: $xsmall) {
              // width: 140px;
          }
      }
  }
  &.mobile-menu-active{
    .logo{
      path{
      // stroke: #fff;
      fill: #fff;
      }
      .picto{
        path{
          fill: #fff!important;
        }
      }
    }
    .burguer{            
        .menu-icon{
            span{                    
                background-color: #FFF;
            }
        }
    }
  }
  &>.row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;    
  }    
  .logo{
    width: 180px;
    transition: all .5s $ease1;
    transform-origin: center;
    z-index: 1;
    position: relative;
    @media (max-width: $xsmall) {
      width: 140px;
    }
    path{
      transition: all 1s $ease1;
    }
    .picto{            
      transform-origin: 60px 60px;
      transform-style: preserve-3D;
    }
      
  }
  .desktop-menu{
    display: block;
    @media (max-width: $small) {
      display: none;
    }
  }
  .burguer{
    display: none;
    @media (max-width: $small) {
      display: flex;
      justify-content: end;
      align-items: center;
      z-index: 999;
      position: relative;
    }

    .menu-icon {
      position: relative;
      width: 50px;
      height: 50px;
      cursor: pointer;
      
      .menu-icon__cheeckbox {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
        z-index: 2;
        -webkit-touch-callout: none;
        position: absolute;
        opacity: 0;
      }
      div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 22px;
        height: 12px;
      }
      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--bar-bg, #000);
        border-radius: 1px;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
    
        &:first-of-type {
          top: 0;
        }
        &:last-of-type {
          bottom: 0;
        }
      }
      &.active,
      .menu-icon__cheeckbox:checked + div {
        span {
          &:first-of-type {
            transform: rotate(45deg);
            top: 5px;
          }
          &:last-of-type {
            transform: rotate(-45deg);
            bottom: 5px;
          }
        }
      }
    
      &.active:hover span:first-of-type,
      &.active:hover span:last-of-type,            
      &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
      &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
          width: 22px;
      }
    
      &:hover {
        // no need hover effect on mobile.
        @media (min-width: 1024px) {
          span:first-of-type {
            width: 26px;
          }
    
          span:last-of-type {
            width: 12px;
          }
        }
      }
    }
  }    
  .header-menu{
    .menu{
      display: flex;
      align-items: center;
      justify-content: flex-end;            
      .menu-item{
        margin: 0 40px;
        position: relative;    
        list-style: none;      
        list-style-position: inherit;      
        &:first-of-type{
          margin-left: 0;
        }
        &:last-of-type{
          margin-right: 0;
        }
        &:after{
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 0%;
          height: 1px;
          background-color: $black;
          transition: all .3s $ease1;
        }
        &.active{
          &::after{
            width: 100%;
          }
        }
        &.current_page_item{
          &::after{
            width: 100%;
          }
        }
        &.current_page_ancestor{
          &::after{
            width: 100%;
          }
        }
        a{
          font-family: 'Poppins', sans-serif;
          @include font-size(18px);
          line-height: 1.2;
          color: $black;
          transition: all 1s $ease1;
          font-weight: 300;                    
        }
        &.menu-item-has-children.active .sub-menu{
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
        .sub-menu{
          opacity: 0;
          visibility: hidden;
          width: 100%;
          position: fixed;
          left: 0;
          top: 80px;
          column-count: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #FFF;
          transition: all .5s $ease1;
          padding: 50px 0;
          z-index: -1;
          pointer-events: none;

          border: 1px solid rgba(27, 27, 33, 0.04);
          box-shadow: 0px 0px 12px 8px rgba(152, 152, 152, 0.04);
          .columns{
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 10px;
            row-gap: 10px;            
          }
          .menu-item{
            margin: 0;
            display: block;
            width: 100%;
            &.current-menu-item{
              a{
                background-color: #F9FAFF;
              }
            }
            a{
              // display: flex;
              // align-items: center;
              // justify-content: flex-start;
              padding: 20px 40px;
              display: flex;
              span{
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                img{
                  width: auto;
                  max-width: 20px;
                  height: auto;
                }
              }
              .caption{
                width: 100%;
                margin-top: 15px;
                color: rgba($black, .4);
              }
            }
            &.active{
              &:after{
                width: 0;
              }
              a{
                background-color: #F9FAFF;
              }              
            }
          }
        }
      }
    }
  }
  .mobile-menu{
    overflow: scroll;
    height: 100%;    
    .row {
      height: 100%;
      .columns{
        height: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;            
      }
    }        
  }
  .mobile-menu,
  .mobile-menu-mask{
      display: block;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      background: $black;
      left: 0;
      transform: translateX(100vw);     
      .header-menu .menu{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;      
        height: auto;
        top: 0;
        position: absolute;
        padding: 100px 0;
        width: 100%;
        .menu-item{
          @include font-size(24px);
          color: white;
          margin: 0;
          width: 100%;
          &:after{
            display: none;
          }
          a{
            color: white;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 48px;
          }
          .sub-menu{
            opacity: 1;
            visibility: visible;
            background-color: transparent;
            position: relative;
            column-count: 1;
            top:0;
            padding:0;
            box-shadow: none;
            z-index: 0;
            pointer-events: all;
            padding: 0px 0px 0px 20px;
            display: block;            
            .columns{
              display: flex;
              position: relative;
              row-gap: inherit;
              a{
                font-size: 20px;
                line-height: 40px;
                padding: 10px 0;
                img{
                  width: auto;                                    
                  max-width: 20px;
                  height: auto;
                  filter: brightness(0) invert(1);
                }
                .caption{
                  color: white;
                  margin-top: -15px;
                }
              }                
            }              
              
          }
        }
      }           
  }
  .mobile-menu-mask{
      background-color: rgba($black, .8);
  }    
}

// .page-template-page-services{
//     .main-header{
//         .header-menu{
//             #menu-primary{
//                 .menu-item{
//                     &::after{
//                         background-color: white;
//                     }
//                     a{
//                         color: white;
//                     }
//                 }
//             }
//         }
//     }    
// }