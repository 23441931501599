.page-template-page-about-us{    
    .values{                   
        display: flex;
        align-items: center;        
        justify-content: flex-start;
        flex-direction: column;
        .row{
          width: 100%;
        }
        h5.title{
          margin-bottom: 100px;
          &.underline{
            &:after{
              background-color: #BDFFE7;
              right: auto;
              left: 0;
            }
          }
        }        
        
        .columns{
          column-gap: 1.5rem;
          row-gap: 1.5rem;
          display: flex;
          flex-flow: wrap;
          justify-content: flex-start;

          .value-wrapper{
            flex-basis: calc(33.33% - 1.5rem);
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 12px 8px rgba(152, 152, 152, 0.07);
            padding: 25px 40px;
            overflow: hidden;
            &:after{
              content:"";
              position: absolute;
              width: 100px;
              height: 100px;         
              border-radius: 50%;     
            }
            &:nth-child(even){
              &:after{
                top: -45px;
                right: -45px;
              }              
            }
            &:nth-child(odd){
              &:after{
                bottom: -45px;
                right: -45px;
              }              
            }
            &:nth-child(1){
              &:after{
                background-color: #D2F7FF;                
              }
            }
            &:nth-child(2){
              &:after{
                background-color: #BDFFE7;                
              }
            }
            &:nth-child(3){
              &:after{
                background-color: #C6D6FF;
              }
            }
            @media (max-width: $small){
              flex-basis: 100%;
              &:nth-child(odd){
                &:after{
                  top: -45px;
                  right: -45px;
                }              
              }
            }
          }
        }        
    }
}